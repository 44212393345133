import { template as template_6b4ec3028123432ab944022ae1969fd0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_6b4ec3028123432ab944022ae1969fd0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
