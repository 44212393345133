import { template as template_12490015df694822ad2da2f64c0e9b43 } from "@ember/template-compiler";
const FKLabel = template_12490015df694822ad2da2f64c0e9b43(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
